<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#3F033B"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs12 py-2 text-left>
          <v-layout wrap>
            <v-flex xs12 px-4 text-left class="mainHeader">PURCHASE DETAILS</v-flex>
          
          </v-layout>
          <v-layout wrap pa-4>
            <v-flex xs4 pr-4>
              <v-card class="pa-4 rounded-xl" >
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Bill No</v-flex>
                  <v-flex xs12 class="tst2">{{paymentList.billNo}}</v-flex>
                </v-layout>
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Purchase Date</v-flex>
                  <v-flex xs12 class="tst2">{{formatDate(paymentList.purchaseDate)}}</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs4 px-4>
              <v-card class="pa-4 rounded-xl">
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Invoice No</v-flex>
                  <v-flex xs12 class="tst2">{{paymentList.invoiceNo}}</v-flex>
                </v-layout>
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Invoice Date</v-flex>
                  <v-flex xs12 class="tst2">{{formatDate(paymentList.invoiceDate)}}</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs4 px-4>
              <v-card class="pa-4 rounded-xl">
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Net.Wt</v-flex>
                  <v-flex xs12 class="tst2">{{paymentList.totalNetWeight}}</v-flex>
                </v-layout>
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Status</v-flex>
                  <v-flex xs12 class="tst2" v-if="isSettled==true">Settled</v-flex>
                  <v-flex xs12 class="tst2" v-else>Not Settled</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
            <!-------------------------------ITEMS--------------------------------->
          <v-layout wrap>
            <v-flex xs12 px-4 text-left class="subhead4">ITEMS</v-flex>
          
          </v-layout>
          <v-layout wrap pa-4 v-if="dataList">
              <v-flex xs12 v-if="dataList.length>0">
                <v-simple-table class="pa-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                        S.No.
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Design
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Count
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Item/weight(gm)
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Net.wt(gm)
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Cost(%)
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Stone Price(₹)
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          Stone Wt(gm)
                        </th>
                        <!-- <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                         TOTAL(Rs.)
                        </th> -->
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                         Touch Rate(%)
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                         Thankam(gm)
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                         Status
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                         Pending Thankam
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                          <tr
                            v-for="(value, i) in dataList"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.code
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.count"> {{ value.count }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.taxableAmount">{{
                                value.taxableAmount
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.touchRate">{{
                                value.touchRate
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.thankam">{{
                                (value.thankam).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td >
                              <span v-if="value.settleStatus">{{value.settleStatus}}</span> -->
                              <!-- <span v-else>Not Settled</span> -->
                            <!-- </td> -->
                            <td>
                        <span v-if="value.settleStatus=='Settled'">Settled</span>
                        <span v-else-if="value.settleStatus=='notSettled'">Not Settled</span>
                        <span v-else-if="value.settleStatus=='partiallySettled'">Partially Settled</span>
                        <span v-else>-</span>
                      </td>
                            <td ><span v-if="value.tobePaidThankam">{{ (value.tobePaidThankam).toFixed(2) }}</span>
                              <span v-else>0</span></td>
                          </tr>
                        </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
             <!-------------------------------SETTLEMENT--------------------------------->
             <v-layout wrap>
            <v-flex xs8 px-4 text-left class="subhead4">PURCHASE SETTLEMENT</v-flex>
            <v-flex xs4 text-right v-if="isexpand == true">
                        <v-icon @click="isexpand = false"
                          >mdi-chevron-up</v-icon
                        >
                      </v-flex>
                      <v-flex xs4 text-right v-if="isexpand == false">
                        <v-icon @click="isexpand = true"
                          >mdi-chevron-down</v-icon
                        >
                      </v-flex>
                      </v-layout>
                      <v-layout wrap v-if="isexpand == false" py-2>
                      <v-flex xs3 pl-4 >
                        <v-divider></v-divider>
                      </v-flex>
          </v-layout>
          <v-layout wrap pa-4 v-if="isexpand == true">
            <detailPagePurchseSettlement/>
          </v-layout>
            <!-------------------------------PAYMENTS--------------------------------->
            <v-layout wrap>
            <v-flex xs12 px-4 text-left class="subhead4">PAYMENTS</v-flex>
           
          </v-layout>
          <v-layout wrap pa-6 v-if="settlementList" style="background-color:white">
              <v-flex xs12 v-if="settlementList.length>0" >
                <!-- <v-card> -->
                <v-layout wrap>
                  <v-flex xs1 px-2  class="text-left subhed"
                          style="font-size: 15px; color: black">No.</v-flex>
                  <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Date</v-flex>
                          <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Thankam Rate(₹)</v-flex>
                          <!-- <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Purchased Thankam(gm)</v-flex> -->
                  <!-- <v-flex xs1 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Mode of payment</v-flex> -->
                          <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Settled Thankam(gm)</v-flex>
                  <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Amount Paid(₹)</v-flex>
                          <!-- supplier balance thankam is => "Excess Thankam(gm)", field name changed only -->
                          <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Excess Thankam(gm)</v-flex>           
                </v-layout>
              <!-- </v-card> -->
                </v-flex>
                <v-flex xs12 py-2 v-for="(item, j) in settlementList"
                            :key="j">
                 
                <v-layout wrap py-2>
                  <v-flex xs1 px-2> <v-card class="pa-2">{{ j+1 }}</v-card></v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.create_date">{{ formatDate(item.create_date) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                  </v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.thankamRate">{{ (item.thankamRate).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex>
                  <!-- <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.totalThankamWeight">{{ (item.totalThankamWeight).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex> -->
                  <!-- <v-flex xs1 pr-2>
                    <v-card class="pa-2" v-if="item.paymentType">{{ item.paymentType }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex> -->
                    <!-- <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.paidThankam">{{ (item.paidThankam).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex> -->
                    <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.settledThankam">{{ (item.settledThankam).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.payAmount">{{ (item.payAmount).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                 </v-flex>
                 <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.balanceThankam">{{ (item.balanceThankam).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>0</v-card>
                 </v-flex>
                 
                </v-layout>
            
              </v-flex>
                </v-layout>
            <!------------------------------------------------------------------->

        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
    import detailPagePurchseSettlement from "./detailPagePurchseSettlement";
  import axios from "axios";
  export default {
    components: {
      detailPagePurchseSettlement,
  },
    data() {
      return {
        isexpand:true,
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        timeout: 2000,
        msg: null,
        page: 1,
        Pagelength: null,
        dataList:[],
        paymentList:[],
        settlementList:[],
      };
    },
    mounted() {
      this.getData();
      this.getPayment();
    },
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
    },
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/purchaseItem/details",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            page: this.page,
            count: 20,
            purchaseId: this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.dataList = response.data.data;
              this.paymentList = response.data.purchasebills;
              this.isSettled = response.data.purchasebills.isSettled;
              this.Pagelength = Math.ceil(response.data.count / 20);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      getPayment() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/purchase/paymentdetails",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            page: this.page,
            count: 20,
            purchaseId: this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.settlementList = response.data.payment;
              this.Pagelength = Math.ceil(response.data.count / 20);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      formatDate(item) {
        var dt = new Date(item);
        var day = dt.getDate();
        var year = dt.getFullYear();
        // var hours = dt.getHours();
        // var minutes = dt.getMinutes();
        dt = dt.toString();
        // var ampm = hours >= 12 ? "pm" : "am";
        // hours = hours % 12;
        // hours = hours ? hours : 12;
        // minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime =
          day +
          " " +
          dt.slice(4, 7) +
          " " +
          year
          //  +
          // " , " +
          // hours +
          // ":" +
          // minutes +
          // " " +
          // ampm;
  
        return strTime;
      },
    },
  
  };
  </script>
    