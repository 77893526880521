<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
   
      <v-flex xs12  text-left>
        <v-card
          tile elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout py-4 v-if="check1==true">
            <v-flex xs12 v-if="savedList">
              <v-card elevation="0" v-if="savedList.length > 0" class="px-4">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablefont">
                              <!-- :disabled="check2 == false ? true : false" -->

                              <!-- <v-checkbox
                                v-model="isSelectAll"
                                hide-details
                                class="mt-0"
                                @click="markAll()"
                              ></v-checkbox> -->
                              <!-- Select -->
                            </th>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont" style="width: 130px!important;">
                              <b>TOUCH RATE (%)</b>
                            </th>
                            <!-- <th class="text-left tablefont"><b>CATEGORY</b></th>
                            <th class="text-left tablefont"><b>ITEM</b></th> -->
                            <th class="text-left tablefont"><b>DESIGN</b></th>
                            <th class="text-left tablefont"><b>COUNT</b></th>
                            <!-- <th class="text-left tablefont">
                              <b>ITEM/WT (gm)</b>
                            </th> -->
                            <th class="text-left tablefont"><b>NET.WT (gm)</b></th>
                            <!-- <th class="text-left tablefont"><b>Gross.Wt </b></th> -->
                            <!-- <th class="text-left tablefont"><b>COST (₹)</b></th> -->
                            <!-- <th class="text-left tablefont">
                              <b>STONE PRICE (₹)</b>
                            </th>
                            <th class="text-left tablefont">
                              <b>STONE WT (gm)</b>
                            </th> -->
                            <!-- <th class="text-left tablefont"><b>TOTAL(Rs.)</b></th> -->
                            <th class="text-left tablefont">
                              <b>THANKAM RATE (₹) </b>
                            </th>
                           
                            <th class="text-left tablefont">
                              <b>THANKAM (gm)</b>
                            </th>
                            <!-- <th class="text-left tablefont">
                              <b>UNPAID GOLD (gm)</b>
                            </th> -->
                            <th class="text-left tablefont">
                              <b>PENDING THANKAM(gm)</b>
                            </th>
                            <th class="text-left tablefont">
                              <b>STATUS</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in savedList"
                            :key="i"
                            class="table"
                          >
                          <td>
                              <v-checkbox
                                      color="#3F053C"
                                :disabled="
                                  value.touchRate == '0' ||value.touchRate == '' ||value.touchRate>100 || value.settleStatus == 'Settled'? true : false
                                "
                                @click="selectThis(value.checkbox, value._id)"
                                v-model="value.checkbox"
                                hide-details
                              ></v-checkbox>
                            </td>
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span class="table">
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
                              <v-text-field
                                :disabled="
                                  value.checkbox == true || value.settleStatus == 'partiallySettled' || value.settleStatus == 'Settled' ? true : false
                                "
                                placeholder="0"
                                color="#b234a9"
                                type="number"
                                outlined style="border-color: rgba(192, 0, 250, 0.986);"
                                flat
                                :rules="[maxValueRule,minValueRule]"
                                @input="cal7(i)"
                                v-model="value.touchRate"
                                dense class="py-2"
                                hide-details="auto"
                              ></v-text-field>
                              </v-form>
                            </td>
                            <td>
                              <span class="table" v-if="value.designId">{{
                                value.designId.code
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span class="table" v-if="value.initialCount"> {{ value.initialCount }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span class="table" v-if="value.initialnetWeight">{{
                                (value.initialnetWeight).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                           

                            <td>
                              <!-- <v-text-field 
                              v-if="value.purchaseId.isLock==true"
                              :disabled="value.checkbox ==true"
                              v-model="value.thankamRate"
                placeholder="Thankam Rate"
                outlined
                append-icon="mdi-lock"
                dense
                hide-details
              >
            </v-text-field> -->
                              <v-text-field
                                :disabled="
                                  value.checkbox == true || value.isLock == true || value.settleStatus == 'partiallySettled'
                                    ? true
                                    : false
                                "
                                v-model="value.thankamRate"
                                placeholder="Thankam Rate"
                                solo style="border-radius: 4px!important;"
                                flat class="table"
                                color="#b234a9"
                                dense
                                hide-details
                              ></v-text-field>
                            </td>
                         
                            <td><span class="table" v-if="value.thankam">{{ (value.thankam).toFixed(2) }}</span>
                              <span v-else>0</span></td>
                              <!-- <td><span v-if="value.pendingGoldWeight">{{ (value.pendingGoldWeight).toFixed(2) }}</span>
                              <span v-else>0</span></td> -->
                            <td><span class="table" v-if="value.tobePaidThankam">{{ (value.tobePaidThankam).toFixed(2) }}</span>
                              <span v-else>0.00</span></td>
                              <!-- <td>
                              <span class="table" v-if="value.settleStatus">{{
                                value.settleStatus
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                        <span v-if="value.settleStatus=='Settled'">Settled</span>
                        <span v-else-if="value.settleStatus=='notSettled'">Not Settled</span>
                        <span v-else-if="value.settleStatus=='partiallySettled'">Partially Settled</span>
                        <span v-else>-</span>
                      </td>
                            <!-- <td><v-icon  @click="(deletedialog=true)(deleteItem=value)">mdi-delete</v-icon></td> -->
                            <td><v-btn class="buttons1" v-if="value.settleStatus!='notSettled'" small dense color="#3F053C" dark @click="resetsettlement(value)">Edit</v-btn></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-card tile v-else elevation="0" class="tablefont1">No record found</v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-end>
            <v-flex xs12 align-self-end>
              <v-layout wrap justify-end class="px-4" v-if="flag1 == true">
                <v-flex v-if="selected.length > 0" xs12 sm6 pt-2 pt-sm-0 text-left class="subhed2"
                  >Balance Thankam : {{ (prevThankam).toFixed(2) }}</v-flex
                >
                <v-flex
                  v-if="selected.length > 0"
                  xs12 sm4
                  align-self-center
                  pr-4 pt-2 pt-sm-0
                  class="subhed2"
                  text-right
                >
                  Total Thankam :
                </v-flex>
                <v-flex
                  v-if="selected.length > 0"
                  xs12 sm2 
                  class="subhed3"
                  text-center
                >
                  <v-card ma-4 outlined class="py-2">
                    <span class="subhed2" v-if="totalThankam">{{
                      totalThankam.toFixed(2)
                    }}</span>
                    <span v-else>-</span>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout
            wrap
            px-4
            pb-2
            v-if=" selected.length > 0"
          >
            <v-flex xs12 sm3 md2 pt-2 pt-sm-0>
              <v-layout pr-2 wrap>
                <v-flex xs12>
                  <span class="subhed2">Payment Type</span>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    solo
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    hide-details
                    dense
                    v-model="paymentTypes"
                    :items="type"
                    label="Select Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 v-if="paymentTypes == 'Cash'">
              <v-layout px-2 wrap v-if="check == false">
                <v-flex xs12>
                  <span class="subhed2">Cash</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    autofocus
                    v-model="cash"
                    solo
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    @input="cal3()"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 v-if="paymentTypes == 'Cash'">
              <v-layout px-2 pb-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Eq.Thankam</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    disabled
                    v-model="eqthankam"
                    solo
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex
              xs12 sm3 md2
              align-self-end
              pb-4 pt-2 pt-sm-0
             
            >
              <v-btn
                color="#3F053C"
                class="button1"
                dark
                @click="AddItem()"
                >Generate Bill</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
        <!-- <v-dialog width="400px" v-model="deletedialog">
          <v-card width="400px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Delete</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-card-text class="pa-4"
              >Are you sure you want to delete this item from purchase?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="deletedialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dense
                dark
                @click="deletesettlement()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      type: ["Cash", "Thankam"],
      isSelectAll: false,
      savedList: [],
      selected: [],
      thankam3: null,
      gold3: null,
      prevThankam:"0",
      CalculatedBalanceThankam:0,
      // prevThankam:0,
      check2:false,
      sflag: true,
      paymentTypes: [],
      paytype: null,
      type1: [],
      cash: 0,
      isLock: false,
      thankam1: 0,
      Weight: 0,
      Price1: 0,
      touchrate: 0,
      pay: false,
      proceed: false,
      balanceThankam: 0,
      totalThankam: 0,
      eqthankam: 0,
      check3: false,
      gold: 0,
      gold1: 0,
      purchasedata: {},
      invoiceDate: null,
      invoiceDate1: null,
      newbilldate1: null,
      newbilldate: null,
      // thankamRate: localStorage.getItem("thankamRate"),
      thankamRate: null,
      checkvalue: false,
      check8:false,
      purchaseId: null,
      totalmakingcharge: 0,
      totalnetweight: 0,
      initialnetWeight:0,
      totalAmount: 0,
      check1:false,
      purchaseId2: null,
      calresponse: {},
      payment: [
        {
          paymentType: null,
          payAmount: null,
        },
      ],
      thankamDetails: [
        {
          thankamId: "63d3a2d42d13b00bbea3a98f",
          boardingRate: null,
          thankamWeight: null,
          batchCode: null,
        },
      ],
      flag1: false,
      GstStat: "GST",
      paymentDetails: [],
      check: false,
      // deletedialog:false,
      deleteItem:[],
      addcat:false,
    };
  },

  mounted() {
    this.getData();
    // this.savedListCopy = this.savedList;

  },
  watch: {
    selected() {
      // this.calctotal();
      this.calthankamTotal();
      console.log("1")
    },
    // paymentTypes() {
    //   this.checkpaymenttype();
    // },
  },
  methods: {
    maxValueRule(value) {
      if (value > 100) {
        return "Max.value is 100";
      }
      return true;
    },
    minValueRule: (value) => {
      if (value < 1) {
        return 'Min.value is 1'
      } else {
        return true
      }
    },
    cal3() {
      console.log("thankamRate", this.thankamRate);
      var eqthankam1 = this.cash / this.thankamRate;
      this.eqthankam = eqthankam1.toFixed(2);
      console.log("eqthankam", this.eqthankam);

    },
    cal7(i) {
      this.check1=false;
      this.newtouchrate = parseFloat(this.savedList[i].touchRate);
      console.log("initialnetWeight==", this.savedList[i].initialnetWeight);
      console.log("newtouchrate==", this.newtouchrate);

      this.savedList[i].thankam =
        this.savedList[i].initialnetWeight * (this.newtouchrate / 100);
      console.log("value==", this.savedList[i].thankam);

      this.savedListCopy = this.savedList;
      console.log("newarray=", this.savedListCopy);
      this.savedList = this.savedListCopy
      this.check1=true;
    },
    markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = true;
          this.selected.push(this.savedList[i]._id);
        }
        console.log("markeD", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = false;
          this.selected = [];
        }
        console.log("Rem", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id) {
      this.isSelectAll = false;
      console.log("id=", id);
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
        }
      } else {
        var ar = this.selected;
        this.selected = ar.filter((x) => x != id);
      }

      console.log("selected id==", this.selected);
      console.log("savedListCopy==", this.savedListCopy);

    },
    calthankamTotal(){
      var finalArray = this.savedListCopy.filter(x=>this.selected.includes(x._id.toString()))
      console.log("array=",finalArray)
      console.log("len=",finalArray.length)

      var thankamTotal1=0;
      var thankamTotal=0;

      for(var i=0;i<finalArray.length;i++){
      thankamTotal1 = Math.abs(finalArray[i].thankam)
        thankamTotal+= thankamTotal1;
        
      }
   

      this.totalThankam=thankamTotal;
      console.log("totalThankam=",this.totalThankam)
      console.log("2")
      this.calctotal();
    },
    calctotal() {
      if(this.selected.length>0){
      axios({
        method: "Post",
        url: "/multiple/purchase/itemcalculation",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          items: this.savedListCopy,
          currentpurchaseItemId: this.selected,
          supplierId: this.supplierId,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.calresponse = response.data.finalcalculation;
            this.thankamRate = response.data.finalcalculation.thankamRate;
            this.totalThankam=response.data.finalcalculation.totalThankam;
            console.log("tt==",response.data.finalcalculation.totalThankam)
            this.prevThankam = response.data.finalcalculation.balanceThankam;
            if (this.prevThankam) {
              this.pay = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
      }
    },
    getData() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/item/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          billNo: this.$route.query.billno,
          purchaseId: this.$route.query.id,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.savedList = response.data.data;
      this.savedListCopy = this.savedList;
console.log("savedListCopy",this.savedListCopy)
      this.check1=true;
            if (this.savedList.length > 0) {
              this.flag1 = true;
            } else {
              this.flag1 = false;
            }
            this.paymentDetails = response.data.payment;
            console.log("pay=", response.data.payment);
            this.purchasedata = response.data.purchasedata;
            this.supplierId = this.purchasedata.supplierId._id;
            this.isLock = response.data.purchasedata.isLock;
            this.newbilldate = response.data.purchasedata.create_date;
            this.newbilldate1 = this.formatDate(this.newbilldate);
            this.invoiceDate = response.data.purchasedata.invoiceDate;
            this.invoiceDate1 = response.data.purchasedata.invoiceDate;
            this.invoiceDate1 = this.formatDate(this.invoiceDate1);
            console.log("invoiceDate1=", this.invoiceDate1);
            this.thankamRate = response.data.purchasedata.thankamRate;
            this.thankam1 = this.thankamRate;
            this.invoiceDate = this.invoiceDate1;
            this.purchaseno = response.data.purchasedata._id;
            this.totalMakingCharge = response.data.totalMakingCharge;
            this.totalnetweight = response.data.totalnetweight;
            this.finalTotal = response.data.finalTotal;
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddItem() {
      axios({
        url: "/purchase/payment",
        method: "POST",
        data: {
          balanceThankam: this.balanceThankam,
          currentpurchaseItems: this.selected,
          paymentType: this.paymentTypes,
          payAmount: this.cash,
          totalThankamWeight: this.totalThankam,
          touchRate: this.touchrate,
          gold: this.gold,
          thankamRate: this.thankamRate,
          purchaseId: this.purchaseno,
          // paidThankam: this.CalculatedBalanceThankam,
          paidThankam: parseFloat(this.eqthankam),

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.selected = [];
            this.paymentTypes = [];
            this.cash = 0;
            this.totalThankam = 0;
            this.thankamRate = 0;
            this.eqthankam = 0;
            this.getData();
            // this.$router.push('/PurchaseList');
            // this.check = true;
            // console.log("check=", this.check);
            // this.returndata = response.data;
            location.reload();
          } else {
            this.check = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    thankamCal() {
      axios({
        url: "/purchaseitem/calculation",
        method: "POST",
        data: {
          items: this.savedListCopy,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.savedListCopy = [];
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    setLockoff() {
      this.isLock = false;
      localStorage.setItem("isLock", this.isLock);
      console.log("lock", this.isLock);
      this.LockItem();
    },
    LockItem() {
      axios({
        url: "/purchase/lock",
        method: "POST",
        data: {
          thankamRate: this.thankamRate,
          purchaseId: this.purchaseno,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    deletesettlement(){
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/settlement/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          thankam: this.deleteItem.thankam,
          id: this.deleteItem._id,
          tobePaidThankam: this.deleteItem.tobePaidThankam,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.paymentDetails = response.data.payment;
            this.snackbar = true;
            this.msg = response.data.msg;
            this.selected = [];
            this.paymentTypes = [];
            this.cash = 0;
            this.totalThankam = 0;
            this.thankamRate = 0;
            this.getData();
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    resetsettlement(item){
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/settlement/reset",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          thankam: item.thankam,
          id: item._id,
          tobePaidThankam: item.tobePaidThankam,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.paymentDetails = response.data.payment;
            this.snackbar = true;
            this.msg = response.data.msg;
            this.selected = [];
            this.paymentTypes = [];
            this.cash = 0;
            this.totalThankam = 0;
            this.thankamRate = 0;
            this.getData();
            this.$refs.addcat.resetValidation();

          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
